import { runInAction } from 'mobx';
import { isBoolean, mergeWith } from 'lodash';
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';

import footerMenuQuery from '@/components/layout/Footer/FooterMenu/footerMenu.graphql';
import headerMenuQuery from '@/components/layout/Header/Navigation/headerMenu.graphql';
import GraphQLError from '@/errors/GraphQLError';
import { initStores, serializeStores } from '@/hooks/useStores';
import Filter from '@/models/Filter';
import { SSRContext } from '@/typings/SSRContext';

import { createClient } from './urqlClient';

interface DefaultProps {
  uri: string;
}

type ServerSidePropsCallback<T> =
  | Promise<GetServerSidePropsResult<T | DefaultProps> | void>
  | GetServerSidePropsResult<T | DefaultProps>
  | void;

interface Options {
  // Ability to cache page with CDN
  cache?: boolean | CacheOptions;
  isEnabled?: (context: SSRContext) => Promise<boolean>;
}

interface CacheOptions {
  // Default is 300 seconds
  maxAge?: number;
  // Default is 86400 seconds (1 day)
  swrAge?: number;
}

export async function getServerSidePropsWithGlobalData<T>(
  ctx: GetServerSidePropsContext,
  fn: (context: SSRContext) => ServerSidePropsCallback<T>,
  options?: Options,
) {
  const [urqlClient, ssrCache] = createClient(ctx);
  const [urqlNoCacheClient] = createClient(ctx);
  const site = ctx.locale || 'mymoto';

  // Init stores
  const stores = initStores();
  await stores.dealershipsMap.init(urqlNoCacheClient, site);
  await stores.globals.init(urqlNoCacheClient, site);

  // Fetch global query data
  const results = await Promise.allSettled([
    urqlClient.query(headerMenuQuery, { site }).toPromise(),
    urqlClient.query(footerMenuQuery, { site }).toPromise(),
  ]);

  // Throw the first error
  for (const result of results) {
    if (result.status === 'fulfilled') {
      if (result.value.error) {
        throw new GraphQLError(result.value.error, result.value.operation);
      }
    }
  }

  runInAction(() => {
    stores.appState.useCloseIconModal = true;
    stores.directoryStore.urqlClient = urqlNoCacheClient;
  });

  const callbackContext: SSRContext = {
    nextCtx: ctx,
    urqlClient,
    urqlNoCacheClient,
    stores,
  };

  if (!!options?.isEnabled) {
    const checkIfEnabled = await options.isEnabled(callbackContext);

    if (!checkIfEnabled) {
      return { notFound: true };
    }
  }

  const result = await fn(callbackContext);

  const isRedirect = result && 'redirect' in result;
  const isNotFound = result && 'notFound' in result && result.notFound;
  if (!!options?.cache && !isRedirect && !isNotFound && 'res' in ctx) {
    const maxAge = !isBoolean(options?.cache) ? options?.cache?.maxAge || 300 : 300;
    const swrAge = !isBoolean(options?.cache) ? options?.cache?.swrAge || 86400 : 86400;
    ctx.res.setHeader('Cache-Control', `s-maxage=${maxAge}, stale-while-revalidate=${swrAge}`);
  }

  const urqlState = ssrCache?.extractData();

  return mergeWith(
    {
      props: {
        urqlState,
        mobxState: serializeStores(stores),
      },
    },
    result || {},
  );
}

export function getDealerFilterString(dealershipsArray: Dealership[]) {
  const dealerFilters = dealershipsArray.map((d) => new Filter<string>('dealerID', d.dealeridentifier || undefined));
  return Filter.toQueryString(dealerFilters);
}
